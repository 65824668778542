import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [0,5,9,10];

export const dictionary = {
		"/": [11],
		"/(customer)/bill": [16,[3]],
		"/(customer)/checkout": [~17,[3]],
		"/delete-account": [22],
		"/faq": [23],
		"/generic/payments/[orderPaymentId]": [24,[5]],
		"/generic/payments/[orderPaymentId]/pay": [25,[5]],
		"/generic/payments/[orderPaymentId]/reciept": [26,[5]],
		"/menu": [27,[6],[7]],
		"/menu/[itemId]": [28,[6,8],[7]],
		"/(customer)/pay": [~18,[3]],
		"/(authorized)/profile/account": [~12,[2]],
		"/(authorized)/profile/order": [~13,[2]],
		"/(authorized)/profile/payment-method": [~14,[2]],
		"/(authorized)/profile/transaction": [~15,[2]],
		"/(customer)/reciept": [19,[3]],
		"/register": [~29],
		"/signin": [~30],
		"/staff-tips/[staffId]": [31],
		"/(customer)/table": [21,[3,4]],
		"/(customer)/tab": [20,[3]],
		"/version": [32],
		"/webview/place/[placeid]": [33,[9]],
		"/webview/place/[placeid]/menuItem/[menuitemId]": [36,[9]],
		"/webview/place/[placeid]/menu/[menuId]": [34,[9,10]],
		"/webview/place/[placeid]/menu/[menuId]/[itemid]": [35,[9,10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';